import Hero from "../atoms/Hero"
import "../site.scss"
import "./home.css"
import CC from "../media/cc.png"
import { Button } from "antd"
import { useNavigate } from "react-router-dom"
import HeroExtras from "../atoms/HeroExtras"
import AnimatedTextArea from "../atoms/AnimatedTextArea"
import Vis from "../media/nets.jpg"
import Footer from "../atoms/Footer"
import ScrollToTop from "../atoms/ScrollToTop"
import CareerForm from "../atoms/CareerForm"
import { useState } from "react"


const HomeMain = ()=>{
    const [isModalOpen, setIsModalOpen] = useState(false);
     // Function to open modal
  const openModal = () => setIsModalOpen(true);

  // Function to close modal
  const closeModal = () => setIsModalOpen(false);


    const navigation = useNavigate()

    const teampage = ()=>{
        navigation("/our-team")
    }

    const solutionsPage = ()=>{
        navigation("/about")
    }



    return <div className="main-div">
        <section className="section-two">
            <Hero/>
            {/* <img src={CC} className="cc-soon" />
            <h1 className="coming-soo">Site under constraction</h1>
            <Button onClick={teampage} type="primary" className="view-teams-btn">View our team</Button> */}

        </section>
   
        <section className="section-one">
            <HeroExtras/>
        </section>
        <section className="section-two">
            <AnimatedTextArea/>
        </section>
        <section className="section-two">
<div className="home-inner-element">
    <div className="hie-box">
        <div className="overlay-bg">
<div className="left-ol2">
    <div className="line"></div>
    <p className="catch-phrase">SiSi AI develops proprietary machine learning models from scratch</p>
<button onClick={solutionsPage} className="line-btn">Learn More</button>
</div>
        </div>
        
    </div>

</div>
        </section>

        <section className="section-two">
<div className="home-team-section">
<div className="overlay-hts ">
    <div className="right-box">

    </div>
    <div className="left-box">
        <p className="box-career-text">Careers</p>
        <p className="box-heading">Working with us</p>
        <p className="box-desc">
        We’re growing fast and searching for passionate, solution-driven individuals to help us create something truly transformative.

Explore our career page for available roles. Don’t see a perfect match? 
No problem—fill out our form for the role you’re excited about, and we’ll be in touch!
        </p>

        <button onClick={openModal} className="reachout-btn">Join SiSi Ai</button>
        <CareerForm isModalOpen={isModalOpen} closeModal={closeModal} />

        </div>
</div>
</div>
        </section>

        <section className="section-two">
<div className="reach_out_section">
    <div className="call-to-action">
<p className="findout">Find out how SISI AI can provide your organisation with a transformational competitive edge</p>
<button onClick={teampage}  className="cta-button">CONTACT US</button>

    </div>
    {/* <div className="call-to-action-text">
<p className="socials-cta">Download the SISI AI brochure</p>
<p className="socials-cta-2">Find out more about SISI AI</p>
<button onClick={solutionsPage}  className="cta-button">WRITE TO US</button>
    </div> */}

</div>
        </section>
<ScrollToTop/>
<Footer/>

    </div>
}


export default HomeMain