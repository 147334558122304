import React from 'react'
import HH from '../pages/HomeMain';
import Header from './Header';
function RoutesMain() {
    return (
        <div>
             <Header/>
             <HH/>
        </div>
      )
}

export default RoutesMain