import React, { useState } from "react";
import { Modal, Form, Input, Button, Progress, notification, message as AntMessage } from "antd";
import emailjs from "emailjs-com";
import "./career_form.css";

const CareerForm = ({ isModalOpen, closeModal }) => {
  const [form] = Form.useForm();
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleSendEmail = async (e) => {
    e.preventDefault()
    try {
      const formValues = await form.validateFields(); // Validate form fields
      const formElement = document.getElementById("careerForm");

      // Show progress
      setProgress(30);
      setLoading(true);

      // Send form data using EmailJS
      const serviceID = "service_gztdrvp";
      const templateID = "template_ogzeoyq";
      const userID = "ROAUHoi4p7QEQOVfz";
      await emailjs.sendForm(serviceID, templateID, formElement, userID);

      setProgress(100);
      notification.success({
        message: "Form Submitted",
        description: "Your resume has been successfully submitted!",
      });

      // Reset progress and form after success
      setTimeout(() => {
        setProgress(0);
        closeModal();
        form.resetFields();
      }, 1000);
    } catch (error) {
      console.error("Failed to send email:", error);
      notification.error({
        message: "Submission Failed",
        description: "There was an issue submitting your form. Please try again.",
      });
      setProgress(0); // Reset progress on failure
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    form.resetFields(); // Reset form
    setProgress(0); // Reset progress bar
    closeModal(); // Close modal
  };

  return (
    <Modal
      title="Send Your Resume"
      open={isModalOpen}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleSendEmail} loading={loading}>
          Submit
        </Button>,
      ]}
    >
      <Form
        id="careerForm"
        form={form}
        name="send_resume"
        layout="vertical"
        onFinish={handleSendEmail}
      >
        <Form.Item
          label="Names"
          name="names"
          rules={[{ required: true, message: "Please input your name!" }]}
        >
          <Input name="names" />
        </Form.Item>
        <Form.Item
          label="Location"
          name="location"
          rules={[{ required: true, message: "Please input your location!" }]}
        >
          <Input name="location" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Please input your email!" },
            { type: "email", message: "Please enter a valid email!" },
          ]}
        >
          <Input name="email" />
        </Form.Item>
        <Form.Item
          label="LinkedIn Account"
          name="linkedin"
          rules={[
            { required: true, message: "Please input your LinkedIn link!" },
            { type: "url", message: "Please enter a valid URL!" },
          ]}
        >
          <Input name="linkedin" />
        </Form.Item>
        <Form.Item
          label="Relevant Skills"
          name="skills"
          rules={[{ required: true, message: "Please input your relevant skills!" }]}
        >
          <Input.TextArea name="skills" />
        </Form.Item>
        {progress > 0 && <Progress percent={progress} status="active" />}
      </Form>
    </Modal>
  );
};

export default CareerForm;
